// TODO: Remove when checkbox is themed
// We can use relative imports for imports from the cdk because we bundle everything
// up into a single flat scss file for material.
@import '~@angular/cdk/overlay';
@import '~@angular/cdk/a11y';
// 
@import '~@angular/material/theming';

// Core styles that can be used to apply material design treatments to any element.

// 
// 
// 
// 
// 
// 
/*! Base styles, made with normalize.css v7.0.0 */
@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;

@font-face {
  font-family: 'Roboto Wide Black';
  font-style: normal;
  font-weight: 900;
  src: url('https://xpodotcom.azureedge.net/xpo/files/roboto-wide--black.woff2') format('woff2'),
    url('https://xpodotcom.azureedge.net/xpo/files/roboto-wide--black.woff') format('woff');
}


*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

:root {
  font-size: $xpo-fontSize--root;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  line-height: $xpo-lineHeight--base;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: $xpo-backgroundColor--body;
  color: $xpo-fontColor--primary;
  font-family: $xpo-fontFamily--primary;
  font-weight: $xpo-fontWeight--normal;
  letter-spacing: $xpo-letterSpacing--base;
  line-height: $xpo-lineHeight--base;
  position: relative;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1,
h2,
h3 {
  margin: ($xpo-contentSpacing / 2) 0;
}

h1 {
  @include TextTreatment-header--large();
}

h2 {
  @include TextTreatment-header--medium();
}

h3 {
  @include TextTreatment-header--small();
}

p {
  margin: 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  border: 0;
  border-top: 1px solid $xpo-grey--300;
  display: block;
  height: 1px;
  margin: $xpo-contentSpacing 0;
  padding: 0;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  color: $xpo-blue--400;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;

  &:hover {
    color: $xpo-blue--300;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dt {
  font-weight: $xpo-fontWeight--bold;
}

dd {
  margin: 0 0 ($xpo-contentSpacing / 2);
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

code,
kbd,
samp {
  background-color: $xpo-grey--300;
  font-family: monospace, monospace;
  font-size: 0.9em;
  padding: 0.2em 0.3em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}


// Mixin that renders all of the core styles that are not theme-dependent.
@mixin xpo-mat-core($typography-config: null) {
  // Provides external CSS classes for each elevation value. Each CSS class is formatted as
  // `mat-elevation-z$zValue` where `$zValue` corresponds to the z-space to which the element is
  // elevated.
  @for $zValue from 0 through 24 {
    .#{$mat-elevation-prefix}#{$zValue} {
      @include mat-elevation($zValue);
    }
  }

  @include angular-material-typography($typography-config);
  @include mat-ripple();
  @include cdk-a11y();
  @include cdk-overlay();
  // @include cdk-text-field();
}

// Mixin that renders all of the core styles that depend on the theme.
@mixin xpo-mat-core-theme($theme) {
  @include mat-ripple-theme($theme);
  @include mat-option-theme($theme);
  @include mat-optgroup-theme($theme);
  @include mat-pseudo-checkbox-theme($theme);
  @include mat-slide-toggle-theme($theme);

  // Wrapper element that provides the theme background when the user's content isn't
  // inside of a `mat-sidenav-container`. Note that we need to exclude the ampersand
  // selector in case the mixin is included at the top level.
  // .mat-app-background#{if(&, ', &.mat-app-background', '')} {
  //   $background: map-get($theme, background);
  //   $foreground: map-get($theme, foreground);

  //   background-color: mat-color($background, background);
  //   color: mat-color($foreground, text);
  // }

  // Marker that is used to determine whether the user has added a theme to their page.
  @at-root {
    .mat-theme-loaded-marker {
      display: none;
    }
  }
}

@mixin xpo-card-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .xpo-Card {
    background: mat-color($background, card);
    border: 1px solid mat-color($foreground, divider);
    color: mat-color($foreground, text);
  }
}

@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;


@mixin xpo-applicationSwitcher-theme($theme) {
  // overlay dialog wrapper
  .cdk-overlay-container .cdk-global-overlay-wrapper.xpo-ApplicationSwitcher-dialog-wrapper {
    height: auto;
    max-height: 100%;

    // overlay panel content style
    .cdk-overlay-pane.xpo-ApplicationSwitcher-dialog {
      width: 100%;
      background-color: $xpo-white;
      // TODO: define if we'll display a border, a shadow or both
      border-bottom: solid 1px $xpo-grey--300;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      min-height: 100px;
    }
  }

  .xpo-ApplicationSwitcherContent {
    // set the content scroll to keep the header always visible
    overflow: auto;

    // fix double bottom border
    // it cant be done inside application component due to the `last-x` selector
    // either inside application category component due to view encapsulation
    .xpo-ApplicationCategory {
      .xpo-Application:last-of-type {
        .xpo-Application-content:hover {
          border-bottom: none;
        }
      }
    }
  }
}

@mixin xpo-autocomplete-theme($theme) {
  @include mat-autocomplete-theme($theme);

  .mat-autocomplete-panel-above {
    &:nth-child(1) {
      border-top: 1px solid $xpo-grey--300;
    }

    & .mat-autocomplete-panel.mat-autocomplete-visible {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  // TODO: this style will be applied to all form field types
  // we need to find the way to use it just for autocomplete controls
  .mat-form-field-suffix {
    .mat-icon {
      cursor: pointer;
    }
  }

  .mat-autocomplete-panel.mat-autocomplete-visible {
    $_Autocomplete-height: 30px;
    $_Autocomplete-line-height: 30px;
    $_Autocomplete-mat-two-lines-height: 45px;
    $_Autocomplete-padding--vertical: 6px;
    $_Autocomplete-max-width-icon: 50px;
    $_Autocomplete-three-columns-size: 33%;

    border: 1px solid $xpo-grey--300;
    border-radius: 0;
    border-top: none;
    box-shadow: none;

    .mat-option {
      border-bottom: 1px solid $xpo-grey--300;
      color: $xpo-black;
      font-size: $xpo-fontSize--root;
      height: $_Autocomplete-height;
      line-height: $_Autocomplete-line-height;
      padding: 0 $xpo-contentSpacing / 2;

      &:hover {
        background-color: $xpo-blue--200;
      }

      &:last-of-type {
        border-bottom: 0;
      }

      .mat-option-text {
        align-items: center;
        display: inline-flex;
      }

      .xpo-Icon {
        margin-right: $xpo-contentSpacing / 4;
      }

      &.xpo-Option {
        &--twoLines {
          height: $_Autocomplete-mat-two-lines-height;
          line-height: normal;
          padding: $_Autocomplete-padding--vertical $xpo-contentSpacing / 2;

          .mat-option-text {
            align-items: center;
            display: flex;

            .xpo-Select-twoLinesContainer {
              flex-grow: 1;
              margin-left: 0;
              overflow: hidden;

              // each of the projected content
              span {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        &-hideDivider:not(:last-of-type) {
          border-bottom: none;
        }
      }

      .mat-icon {
        margin-right: 0;
      }

      .xpo-icon {
        margin-right: $xpo-contentSpacing / 4;
        vertical-align: middle;
      }
    }

    .xpo-Select-twoColumnsContainer {
      display: grid;
      grid-template-columns: 20% 80%;

      // each of the projected content for columns
      span {
        flex: none;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span:first-of-type {
        margin-right: $xpo-contentSpacing / 4;
      }

      span:last-of-type {
        margin-left: $xpo-contentSpacing / 4;
      }
    }

    .xpo-Select-threeColumnsContainer {
      display: grid;
      grid-template-columns: 33% 34% 33%;

      // each of the projected content for columns
      span {
        margin: 0 $xpo-contentSpacing / 4;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span:first-of-type {
        margin-left: 0;
      }

      span:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@mixin dotBadgeMix($backgroundColor) {
  background-color: $backgroundColor;
}
@mixin xpo-badge-theme($theme) {
  $_matBadgeContent-fontSize: 10px;
  $_matBadgeContent-icon-fontSize: 20px;
  $_matBadgeContent-height: 16px;
  $_matBadgeContentDefault-borderRadius: 2px;
  $_matBadgeContent-padding: 2px 3px;
  $_matBadgeContent-border: $ngx-input-border-width solid $xpo-white;
  $_matBadgeContent-right: -15px;
  $_matBadgeContent-borderRadius: 10px;
  $_matDotBadgeContent-heightWidth: 10px;
  $_matBadgeOverlapContent-top: -1px;
  $_matBadgeOverlapContent-right: -14px;
  $_matBadgeContent-top--important: -8px;
  $_matBadgeContent-height--important: 18px;

  .mat-icon {
    &.mat-badge-above:not(.xpo-Badge--important) {
      align-items: center;
      display: inline-flex;
      font-size: $_matBadgeContent-icon-fontSize;
      height: auto;
      width: auto;
    }

    &.mat-badge-medium {
      color: $xpo-grey--900;

      .mat-badge-content {
        align-items: center;
        background-color: $xpo-grey--900;
        border: $_matBadgeContent-border;
        border-radius: $_matBadgeContentDefault-borderRadius;
        display: flex;
        font-size: $_matBadgeContent-fontSize;
        font-weight: $xpo-fontWeight--extraBold;
        height: $_matBadgeContent-height;
        justify-content: center;
        line-height: $_matBadgeContent-height;
        padding: $_matBadgeContent-padding;
        width: auto;
      }

      &.mat-badge-above {
        .mat-badge-content {
          top: -9px;
        }
      }

      &.mat-badge-overlap {
        &.mat-badge-after {
          .mat-badge-content {
            right: $_matBadgeContent-right;
          }
        }
      }

      //Important notification badge
      &.xpo-Badge--important {
        &.mat-badge-above {
          .mat-badge-content {
            top: $_matBadgeContent-top--important;
          }
        }

        &.mat-badge-overlap {
          &.mat-badge-after {
            .mat-badge-content {
              right: -28px;
            }
          }
        }

        .mat-badge-content {
          background-color: $xpo-red--600;
          border-radius: $_matBadgeContent-borderRadius;
          font-weight: $xpo-fontWeight--extraBold;
          height: auto;
          line-height: $_matBadgeContent-fontSize;
          padding: $xpo-contentSpacing / 4 $xpo-contentSpacing / 2;
          width: auto;
        }
      }
      //Dot badge
      &.xpo-BadgeDot {
        .mat-badge-content {
          border: none;
          border-radius: $_matBadgeContent-borderRadius;
          height: $_matDotBadgeContent-heightWidth;
          width: $_matDotBadgeContent-heightWidth;
        }

        &.mat-badge-overlap {
          &.mat-badge-after {
            .mat-badge-content {
              right: $_matBadgeOverlapContent-right;
            }
          }
        }

        &.mat-badge-above {
          .mat-badge-content {
            top: $_matBadgeOverlapContent-top;
          }
        }

        &--warning {
          .mat-badge-content {
            @include dotBadgeMix($ngx-Snackbar-status--warn);
          }
        }

        &--success {
          .mat-badge-content {
            @include dotBadgeMix($ngx-Snackbar-status--success);
          }
        }

        &--info {
          .mat-badge-content {
            @include dotBadgeMix($xpo-blue--700);
          }
        }

        &--info {
          .mat-badge-content {
            @include dotBadgeMix($xpo-blue--700);
          }
        }

        &--important {
          .mat-badge-content {
            @include dotBadgeMix($ngx-Snackbar-status--error);
          }
        }
      }
    }
  }
}

///
// The overrides for buttons
///

/*
  1. The "mat-raised-button" is deprecated but we're keeping
      the styles around so things don't break so badly.
*/

///
// Small Button styles
///

@mixin ngx-ButtonSmall() {
  $_button-border--slim: 1px;
  $_button-fontSize-icon--small: 20px;
  $_button-Height--small: 30px;
  $_button-LineHeight--small: 28px;

  [mat-button].mat-button.xpo-SmallButton,
  [mat-flat-button].mat-flat-button.xpo-SmallButton,
  [mat-raised-button].mat-raised-button.xpo-SmallButton,
  [mat-stroked-button].mat-stroked-button.xpo-SmallButton,
  [mat-icon-button].mat-icon-button.xpo-SmallButton {
    border-width: $_button-border--slim;
    font-size: $xpo-fontSize--medium;
    height: $_button-Height--small;
    line-height: normal;
    min-width: auto;

    .mat-icon {
      font-size: $_button-fontSize-icon--small;
    }

    .mat-button-wrapper {
      line-height: $_button-LineHeight--small;
    }
  }

  mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-SmallButton,
  mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-SmallButton {
    height: $_button-Height--small;

    .mat-button-toggle,
    &.mat-button-toggle {
      border-width: $_button-border--slim;

      .mat-button-toggle-button {
        font-size: $xpo-fontSize--medium;
        height: 100%;
      }
    }
  }
}

///
// Large Button styles
///

@mixin ngx-ButtonLarge() {
  [mat-button].mat-button.xpo-LargeButton,
  [mat-flat-button].mat-flat-button.xpo-LargeButton,
  [mat-raised-button].mat-raised-button.xpo-LargeButton,
  [mat-stroked-button].mat-stroked-button.xpo-LargeButton,
  mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-LargeButton,
  mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-LargeButton {
    $_button-large-height: 50px;

    font-size: $xpo-fontSize--large;
    height: $_button-large-height;
    line-height: normal;

    .mat-button-toggle-button {
      font-size: $xpo-fontSize--extraLarge;
      height: 100%;
    }
  }
}

///
// Action Button styles
///

@mixin xpo-action-button-theme($theme) {
  .xpo-ActionButton {
    background-color: $xpo-white;
    border: $ngx-input-border-width solid $xpo-grey--300;
    color: $xpo-grey--900;
    padding: $xpo-contentSpacing / 8;

    .mat-icon {
      font-size: $xpo-fontSize--large;
      padding: $xpo-contentSpacing / 4;
    }

    &:focus,
    &:active {
      border: $ngx-input-border-width solid $xpo-grey--300;
      color: $xpo-blue--350;
      outline: none;
    }

    &:hover {
      color: $xpo-grey--1100;
    }
  }
}


// Additional parameters that can be use used to configure the button
@function ngx-buttonConfig(
  $hover-color: initial,
  // font color that will be applied on hover to basic buttons
  $primary-hover-color: initial,
  // font color that will be applied on hover to primary buttons
  $accent-hover-color: initial //  font color that will be applied on hover to accent colors
) {
  @return (hover-color: $hover-color, primary-hover-color: $primary-hover-color, accent-hover-color: $accent-hover-color);
}

@mixin xpo-button-disabled($background-color, $border-color, $font-color) {
  &,
  &:active,
  &:hover,
  &:focus {
    background-color: $background-color;
    border-color: $border-color;
    color: $font-color;
    cursor: not-allowed;
  }
}

@mixin xpo-button-theme($theme, $button-config: ngx-buttonConfig()) {
  $_button-padding--horizontal: 20px;
  $_button-border--regular: 2px;

  // --- Button Warn---
  $_button-warn: $xpo-red--400;
  $_button-warn--active: $xpo-red--900;
  $_button-warn--hover: $xpo-red--300;
  $_button-medium-height: 40px;

  // All Buttons
  [mat-button].mat-button,
  [mat-flat-button].mat-flat-button,
  [mat-raised-button].mat-raised-button,
  [mat-icon-button].mat-icon-button,
  [mat-stroked-button].mat-stroked-button {
    border-width: $_button-border--regular;
    font-size: $xpo-fontSize--large;
    font-weight: $xpo-fontWeight--normal;
    height: $_button-medium-height;
    line-height: normal;
    padding: 0 $_button-padding--horizontal;
    transition: color $ngx-button-animationDuration ease, background-color $ngx-button-animationDuration ease, border-color $ngx-button-animationDuration ease;
  }

  // For buttons without border or background we are not using the vertical padding
  [mat-button].mat-button {
    padding: 0;
  }

  // This styles are here to be able to use 'xpoSmallButton' and 'xpoSmallButton'
  // with mat-button-toggle
  mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    height: $_button-medium-height;

    &.mat-button-toggle-vertical {
      .mat-button-toggle + .mat-button-toggle {
        border-top-width: $_button-border--regular;
      }
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle,
    .mat-button-toggle-button {
      border-width: $_button-border--regular;
    }

    .mat-button-toggle-button {
      font-size: $xpo-fontSize--large;
      height: 100%;
    }
  }

  mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    border-width: $_button-border--regular;
  }

  mat-button-toggle-group.mat-button-toggle-group {
    border-width: 0;
  }

  [mat-icon-button].mat-icon-button {
    padding: 0;
  }

  // Flat-button styles #1
  [mat-flat-button].mat-flat-button,
  [mat-raised-button].mat-raised-button {
    border-style: solid;
    border-width: $_button-border--regular;
    box-shadow: none !important; // overrides are a jerk--only for `mat-raised-`
    color: $xpo-white;

    &,
    &.mat-primary {
      background-color: $xpo-blue--800;
      border-color: $xpo-blue--800;

      &:hover {
        background-color: $xpo-blue--350;
        border-color: $xpo-blue--350;
      }

      &:focus,
      &:active {
        background-color: $xpo-blue--850;
        border-color: $xpo-blue--850;
      }
    }

    &.mat-warn {
      background-color: $_button-warn;
      border-color: $_button-warn;

      &:hover {
        background-color: $_button-warn--hover;
        border-color: $_button-warn--hover;
      }

      &:focus,
      &:active {
        background-color: $_button-warn--active;
        border-color: $_button-warn--active;
      }
    }

    &[disabled] {
      @include xpo-button-disabled($xpo-grey--300, $xpo-grey--300, $xpo-white);
    }
  }

  // Stroked Buttons
  .mat-stroked-button {
    background-color: transparent;
    border-color: $xpo-blue--850;
    color: $xpo-blue--850;

    &:hover {
      background-color: $xpo-blue--150;
      border-color: $xpo-blue--800;
      color: $xpo-blue--800;
    }

    &:focus,
    &:active {
      background-color: $xpo-blue--150;
      border-color: $xpo-blue--350;
      color: $xpo-blue--350;
    }

    &[disabled] {
      @include xpo-button-disabled($xpo-grey--80, $xpo-grey--350, $xpo-grey--350);
    }
  }

  // Link buttons and icon buttons
  .mat-button,
  .mat-icon-button {
    background-color: transparent;

    &.mat-primary {
      color: $xpo-blue--400;

      &:hover,
      &:focus {
        color: $xpo-blue--300;
      }

      &:active {
        color: $xpo-blue--900;
      }
    }

    &,
    &.mat-accent {
      color: $xpo-grey--900;

      &:hover,
      &:focus {
        color: $xpo-grey--400;
      }

      &:active {
        color: $xpo-grey--900;
      }
    }

    &.mat-warn {
      color: $_button-warn;

      &:hover,
      &:focus {
        color: $_button-warn--hover;
      }

      &:active {
        color: $_button-warn--active;
      }
    }
  }

  // Buttons with borders
  [mat-flat-button].mat-flat-button,
  [mat-raised-button].mat-raised-button,
  [mat-stroked-button].mat-stroked-button {
    align-items: center;
    display: inline-flex;
  }

  // Disabled states for buttons with no background
  [mat-button].mat-button,
  [mat-icon-button].mat-icon-button {
    &[disabled] {
      @include xpo-button-disabled(transparent, $xpo-grey--350, $xpo-grey--350);
    }
  }

  // Bring in the Small Button Styles
  @include ngx-ButtonSmall();

  // Bring in the Large Button Styles
  @include ngx-ButtonLarge();

  @include xpo-action-button-theme($theme);

  ///
  // Removals
  ///
  .mat-button-focus-overlay,
  .mat-button-toggle-focus-overlay {
    opacity: 0 !important; // (xpo): hide background color;
  }

  // Remove rounded borders
  [mat-stroked-button].mat-stroked-button,
  [mat-raised-button].mat-raised-button,
  [mat-flat-button].mat-flat-button {
    border-radius: $_button-border--regular;
  }
}

///
// Sets up the button toggle group
///

/*
  1. These are carried over from Material but UX does not
      have any design for them yet. That's why they are not
      in the sample... they're a bit of a hidden API.
*/

@import '~@angular/material/theming';

@mixin xpo-button-toggle-group-theme($theme) {
  @include mat-button-toggle-theme($theme);

  mat-button-toggle-group.mat-button-toggle-group,
  mat-button-toggle.mat-button-toggle-standalone {
    $_innerDividerColor: $xpo-blue--850;
    $_innerBorderThickness: 2px;
    $_innerStandaloneButton-margin: 2px; // #1
    $_innerPadding--horizontal: 20px;

    &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
    &.mat-button-toggle-group-appearance-standard {
      border-radius: 2px;

      @at-root .mat-button-toggle-standalone:not(:last-child) {
        // #1
        margin-right: $_innerStandaloneButton-margin;
      }

      @at-root .mat-button-toggle.mat-button-toggle-standalone, // #1
      .mat-button-toggle {
        border-color: $_innerDividerColor;
        border-style: solid;
        color: $xpo-blue--850;
        font-weight: $xpo-fontWeight--medium;
        transition: color $ngx-button-animationDuration ease, background-color $ngx-button-animationDuration ease;

        &:hover,
        &:focus {
          background-color: $xpo-blue--150;
        }

        &:active {
          background-color: $xpo-grey--100;
        }

        &.mat-button-toggle-checked {
          background-color: $xpo-blue--350;
          border-color: $xpo-blue--350;
          color: $xpo-white;
        }

        &.mat-button-toggle-disabled {
          background-color: $xpo-grey--80;
          color: $xpo-grey--350;
          outline: none;

          .mat-button-toggle-button {
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }

      // Button Content
      .mat-button-toggle-label-content {
        line-height: inherit;
        padding: 0 $_innerPadding--horizontal;
      }

      // Overrides for border colors
      .mat-button-toggle + .mat-button-toggle:not(.mat-button-toggle-checked) {
        border-left-color: $_innerDividerColor;
      }

      .mat-button-toggle + .mat-button-toggle.mat-button-toggle-checked {
        border-left-color: $xpo-blue--350;
      }

      &.mat-button-toggle-vertical {
        .mat-button-toggle + .mat-button-toggle {
          border-top-color: $_innerDividerColor;
          border-top-style: solid;
        }
      }
    }
  }

  .mat-button-toggle-standalone.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    border-color: $xpo-grey--350;
  }
}

@mixin xpo-link-theme($theme) {
  $_icon-size: 1.143em; // relative to parent size

  .xpo-Link {
    color: $xpo-blue--800;
    cursor: pointer;
    font-weight: $xpo-fontWeight--medium;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }

    &:hover {
      color: $xpo-blue--350;
      outline: 0;
    }

    &:active,
    &:focus {
      color: $xpo-blue--850;
      outline: none;
    }

    &--regular {
      font-weight: $xpo-fontWeight--normal;
    }

    &--accent {
      color: $xpo-grey--900;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        color: $xpo-grey--900;
        outline: 0;
      }

      &:active {
        color: $xpo-grey--1000;
      }
    }

    .mat-icon {
      font-size: $xpo-header-fontSize--large;
      height: $_icon-size;
      margin-left: $xpo-contentSpacing / 4;
      margin-right: $xpo-contentSpacing / 4;
      vertical-align: middle;
      width: $_icon-size;
    }
  }
}

@mixin xpo-download-button-theme($theme) {
  button.xpo-DownloadButton {
    align-items: center;
    display: flex;

    .mat-spinner {
      display: inline-block;
      left: $xpo-contentSpacing / 4;
    }
  }
}

@mixin xpo-checkbox-theme($theme) {
  @include mat-checkbox-theme($theme);
  $_checkbox-indeterminate-borderRadius: 2px;
  $_checkbox-indeterminate-padding: 1px 2px 3px 1px;
  $_checkbox-indeterminate-margin: 3px 3px;
  $_checkbox-indeterminate-border: 2px solid $xpo-black;

  .mat-checkbox-background {
    transition: none !important; // Avoid default indetermitate style when left indeterminate state
  }

  .mat-checkbox-indeterminate.mat-accent {
    .mat-checkbox-inner-container {
      border: $ngx-input-border-width solid $xpo-black;
      border-radius: $_checkbox-indeterminate-borderRadius;
    }

    &.mat-checkbox-disabled {
      .mat-checkbox-inner-container {
        border: $ngx-input-border-width solid $xpo-grey--350;

        .mat-checkbox-frame {
          border: $ngx-input-border-width solid transparent;
        }
      }
    }

    .mat-checkbox-background {
      background: $xpo-black;
      border: $_checkbox-indeterminate-border;
      height: $xpo-contentSpacing / 2;
      margin: $_checkbox-indeterminate-margin;
      padding: $_checkbox-indeterminate-padding;
      width: $xpo-contentSpacing / 2;
    }
  }

  .mat-checkbox {
    $_mat-checkbox-class: &;

    border: $ngx-input-border-width solid transparent;
    display: inline-flex;
    margin: 3px $xpo-contentSpacing / 4;
    padding: 0 $xpo-contentSpacing / 4;

    &.cdk-keyboard-focused {
      border: $ngx-input-border-width dashed $xpo-grey--300;
    }

    &.xpo-CheckboxGroup-selectAll {
      margin: 3px $xpo-contentSpacing / 2;
    }

    .mat-checkbox-layout {
      align-items: center;
    }

    &.mat-checkbox-label-before .mat-checkbox-inner-container {
      margin-left: $xpo-contentSpacing / 4;
    }

    .mat-checkbox-inner-container {
      align-self: flex-start;
      margin: $xpo-contentSpacing / 4 $xpo-contentSpacing / 4 0 0;
    }

    &-ripple {
      display: none;
    }

    &-background {
      .mat-checkbox-checkmark {
        &-path {
          stroke-width: 3.13333px;
        }
      }
    }

    .mat-checkbox-frame {
      border: none;
    }

    &.mat-checkbox-disabled {
      cursor: not-allowed;

      .mat-checkbox-label {
        color: $xpo-grey--950;
      }

      .mat-checkbox-mixedmark {
        background-color: $ngx-input-color-disabled--dark;
      }

      .mat-checkbox-checkmark {
        fill: $ngx-input-color-disabled--light;
      }

      .mat-checkbox-background {
        background-color: $ngx-input-color-disabled--light;
        border: 1px solid $xpo-grey--350;

        .mat-checkbox-checked & {
          background-color: $xpo-grey--350;
        }

        .mat-checkbox-checkmark-path {
          stroke: $ngx-input-color-disable--lighter !important;
        }
      }

      &.mat-checkbox-indeterminate {
        .mat-checkbox-background {
          background-color: $xpo-grey--300;
        }

        .mat-checkbox-mixedmark {
          background-color: $ngx-input-color-disabled--light;
        }
      }

      .mat-checkbox-frame {
        border-color: $xpo-grey--300;
      }
    }

    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: $ngx-input-color-onActive--dark;
      }

      &.mat-checkbox-disabled {
        .mat-checkbox-background {
          background-color: $ngx-input-color-disabled--dark;
        }
      }
    }

    &:not(.mat-checkbox-disabled) {
      #{$_mat-checkbox-class}-inner-container:hover {
        background-color: transparent;
      }
    }

    &:not(.mat-checkbox-indeterminate) {
      .mat-checkbox-frame {
        border: 1px solid $ngx-input-color-border;
      }
    }

    &.mat-checkbox-indeterminate .mat-checkbox-background {
      background-color: $ngx-input-color-onActive--dark;
    }
  }
}

@mixin chip-size($chip-size, $font-size) {
  font-size: $font-size;
  line-height: $chip-size;
  min-height: $chip-size;
}

@mixin icon-size($icon-size) {
  height: $icon-size;
  width: $icon-size;
}

@mixin chips-badge($backgroundColor, $border, $color) {
  background-color: $backgroundColor;
  border: $border;
  color: $color;
}

@mixin xpo-chips-theme($theme) {
  @include mat-chips-theme($theme);

  mat-chip.mat-chip {
    $_matChip-priority-critical: $xpo-color--error;
    $_matChip-priority-high: $xpo-orange--300;
    $_matChip-priority-medium: $xpo-color--warn;
    $_matChip-border-radius--default: $xpo-contentSpacing;
    $_matChip-tags-border-radius: 2px;
    $_matChip-tagLarge-height: 30px;
    $_matBadgeContent-fontSize: 10px;
    $_matChip-Badge-height: 20px;

    font-weight: $xpo-fontWeight--medium;

    @include chip-size($xpo-lineHeight--base, $xpo-fontSize--medium);

    .mat-icon {
      @include icon-size($xpo-fontSize--medium);
    }

    &.xpo-Chip--small {
      @include chip-size($xpo-lineHeight--small, $xpo-fontSize--small);

      .mat-icon {
        @include icon-size($xpo-fontSize--small);
      }
    }

    &.mat-standard-chip {
      background-color: $xpo-grey--300;
      border-radius: $_matChip-border-radius--default;
      padding-left: $xpo-contentSpacing / 2;
      padding-right: $xpo-contentSpacing / 2;

      &:not(.mat-chip-disabled) {
        &:focus,
        &:active {
          box-shadow: none;
        }
      }

      &::after {
        opacity: 0;
      }

      .mat-icon {
        font-size: inherit;
        margin-right: $xpo-contentSpacing / 4;

        &.mat-chip-trailing-icon {
          height: auto;
          margin-left: $xpo-contentSpacing / 4;
          margin-right: 0;
          width: auto;
        }
      }

      &.xpo-tag {
        background-color: $xpo-grey--100;
        border: 1px solid $xpo-grey--300;
        border-radius: $_matChip-tags-border-radius;

        &:hover {
          background-color: $xpo-white;
        }

        .mat-chip-remove {
          &,
          &:hover {
            color: $xpo-grey--1100;
            opacity: 1;
          }
        }

        .mat-icon {
          font-size: $xpo-header-fontSize--medium;
        }

        &--large {
          color: $xpo-black;
          font-family: $xpo-fontFamily--primary;
          font-size: $xpo-fontSize--medium;
          font-weight: normal;
          height: $_matChip-tagLarge-height;
        }
      }
    }

    &.xpo-Chip {
      display: flex;
      justify-content: center;

      &--unread,
      &--critical {
        background: $_matChip-priority-critical;
        color: $xpo-white;
      }

      &--high {
        background: $_matChip-priority-high;
      }

      &--medium {
        background: $_matChip-priority-medium;
      }

      &--green {
        background-color: $xpo-green--400;
        color: $xpo-white;
      }

      &--orange {
        background-color: $xpo-orange--300;
      }

      &--red {
        background-color: $xpo-red--400;
        color: $xpo-white;
      }

      &--yellow {
        background-color: $xpo-yellow--400;
      }
    }

    &.xpo-BadgeChip {
      &.mat-chip {
        font-size: $_matBadgeContent-fontSize;
        font-weight: $xpo-fontWeight--extraBold;
        min-height: $_matChip-Badge-height;
      }

      // Numbered
      &--negative {
        &.mat-standard-chip {
          &.mat-chip {
            @include chips-badge(
              $_matBadgeContent-backgroundColor--negative,
              $ngx-input-border-width solid $_matBadgeContent-backgroundColor--negative,
              $_matBadgeContent-color--negative
            );
          }
        }
      }

      &--positive {
        &.mat-standard-chip {
          &.mat-chip {
            @include chips-badge(
              $_matBadgeContent-backgroundColor--positive,
              $ngx-input-border-width solid $_matBadgeContent-backgroundColor--positive,
              $ngx-Snackbar-status--success
            );
          }
        }
      }
    }
  }
}

@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;


$_datePicker-input--border: solid 1px $xpo-black;
$_datePicker-input--minWidth: $xpo-contentSpacing * 8;

$_datePicker-popup--marginTopAdjustment: -1px;

$_datePicker-panel--height: 250px;
$_datePicker-panel--width: 226px;

$_datePicker-controlsBar--height: 30px;
$_datePicker-controlsBar--margins: $xpo-contentSpacing * 0.5;
$_datePicker-controlsBar-buttons--thickness: 3px;
$_datePicker-controlsBar-prevNextButtons--size: $xpo-contentSpacing;
$_datePicker-controlsBar--scale: scale(0.5305);

$_datePicker-yearPicker-cellContent--height: 30px;

$_datePicker-multiYear--height: 205px;

@mixin xpo-datePicker-theme($theme) {
  @include mat-datepicker-theme($theme);

  ///
  /// Date Picker Input, calendar button
  ///
  .xpo-DatePicker {
    .mat-datepicker-toggle {
      $_datePicker-toggle--size: 16px;

      display: flex;

      .mat-icon-button {
        height: $_datePicker-toggle--size;
        margin: 0;
        width: $_datePicker-toggle--size;

        .mat-button-wrapper {
          display: flex;

          .mat-icon {
            font-size: $_datePicker-toggle--size;
            height: $_datePicker-toggle--size;
            line-height: $_datePicker-toggle--size;
            width: $_datePicker-toggle--size;
          }
        }
      }
    }

    .mat-form-field-wrapper .mat-form-field-flex {
      .mat-form-field-infix {
        // min-width property set to avoid mat-datepicker-toggle icon overlaps text input
        // based on content spacing
        min-width: $_datePicker-input--minWidth;
      }

      .mat-form-field-suffix {
        $_datePicker-toggle--margin-right: 4px;

        margin-right: $_datePicker-toggle--margin-right;
      }
    }

    // show defined border color in date picker input text
    // when date picker calendar is open
    &--active .mat-form-field-wrapper .mat-form-field-flex {
      border-color: $xpo-black;
    }
  }

  ///
  /// Date Picker Calendar popup
  ///
  .mat-datepicker-popup .mat-datepicker-content {
    border: $_datePicker-input--border;
    border-radius: 0;
    // this is a negative top margin used to show the calendar popup top border
    // and to not showing the input text bottom border
    margin-top: $_datePicker-popup--marginTopAdjustment;

    .xpo-DatePicker-calendar.mat-calendar {
      height: $_datePicker-panel--height;
      width: $_datePicker-panel--width;

      .mat-calendar {
        ///
        /// Styles for calendar header
        ///
        &-header {
          padding-top: 0;
        }

        &-controls {
          align-items: center;
          height: $_datePicker-controlsBar--height;
          justify-content: space-between;
          margin: $_datePicker-controlsBar--margins 0;
        }

        &-period-button {
          color: $xpo-black;
          font-size: $xpo-fontSize--medium;
          font-weight: $xpo-fontWeight--bold;
        }

        &-previous-button,
        &-next-button {
          height: $_datePicker-controlsBar-prevNextButtons--size;
          width: $_datePicker-controlsBar-prevNextButtons--size;

          &::after {
            border-color: $xpo-black;
            border-top-width: $_datePicker-controlsBar-buttons--thickness;
            margin: 0;
          }
        }

        &-previous-button {
          &::after {
            border-left-width: $_datePicker-controlsBar-buttons--thickness;
            transform: $_datePicker-controlsBar--scale translateX($_datePicker-controlsBar-buttons--thickness) rotate(-45deg);
          }
        }

        &-next-button {
          &::after {
            border-right-width: $_datePicker-controlsBar-buttons--thickness;
            transform: $_datePicker-controlsBar--scale translateX(-$_datePicker-controlsBar-buttons--thickness) rotate(45deg);
          }
        }

        ///
        /// Styles for calendar table header
        ///
        &-table-header {
          color: $xpo-black;

          th {
            font-size: $xpo-fontSize--medium;
            font-weight: $xpo-fontWeight--bold;
            padding: 0;
          }

          &-divider {
            display: none;
          }
        }

        ///
        /// Common styles for calendar body views
        ///
        &-body {
          &-label {
            // !important is needed in order to override in-line styles
            padding: 0 !important;
            // for spacing purposes we need to keep the element size
            // so we use visibility: hidden instead of display: none
            // besides this class is apply to a td element
            visibility: hidden;
          }

          &-cell:hover {
            .mat-calendar-body-cell-content {
              background-color: $xpo-blue--100;
              border-color: transparent;
              color: $xpo-black;
            }
          }

          &-cell-content {
            background-color: $xpo-grey--60;
            color: $xpo-black;
            font-size: $xpo-fontSize--medium;
          }

          &-today {
            border-color: $xpo-blue--800;
            color: $xpo-blue--800;
          }

          &-selected {
            background-color: $xpo-blue--800;
            border-color: $xpo-blue--800;
            color: $xpo-white;
            font-weight: $xpo-fontWeight--bold;

            &.mat-calendar-body-today {
              box-shadow: none;
            }
          }
        }
      }
    }

    ///
    /// Styles for calendar year view
    ///
    mat-year-view .mat-calendar-table {
      .mat-calendar-body-cell-content {
        border-radius: 0;
      }
    }

    ///
    /// Styles for calendar multi-year view
    ///
    mat-multi-year-view .mat-calendar-table {
      height: $_datePicker-multiYear--height;

      .mat-calendar-body-cell-content {
        border-radius: 0;
        height: $_datePicker-yearPicker-cellContent--height;
      }
    }
  }
}

@mixin xpo-date-range-picker-theme($theme) {
  // DATE RANGE INPUT FORM FIELD STYLE
  mat-form-field.mat-form-field-type-xpo-date-range-input {
    // use a negative margin to position the label on top for the input control
    // in the same way it is done for "mat-form-field-type-mat-input"
    $_label-top: -24px;
    $_formField-marginTop: 24px;
    $_formField-icon-fontSize: 20px;

    margin-bottom: $xpo-contentSpacing;
    margin-top: $_formField-marginTop;

    &.mat-form-field-appearance-standard {
      .mat-form-field-flex {
        align-items: center;
        padding-top: 0;
      }
    }

    // Input default behavior
    &.mat-form-field-appearance-legacy {
      // Only placeholder
      &.mat-form-field-hide-placeholder {
        .mat-form-field-label {
          font-size: $xpo-fontSize--medium;
          letter-spacing: normal;
          opacity: 0.5;
        }

        .mat-form-field-label-wrapper {
          $_labelWrapper-top--default: -12px;
          $_labelWrapper-left--default: 9px;

          left: $_labelWrapper-left--default;
          overflow: visible;
          top: $_labelWrapper-top--default;
        }

        // Focused or with value
        &.mat-form-field-should-float {
          .mat-form-field-label-wrapper {
            left: 0;
            top: $_label-top;
          }

          .mat-form-field-label {
            font-family: $xpo-fontFamily--primary;
            font-size: $xpo-fontSize--extraLarge; //This size in 18px because the default label with scale(0.75)
            font-weight: normal;
            letter-spacing: inherit;
            opacity: 1;
          }
        }
      }
    }

    &.mat-form-field-appearance-legacy,
    &.mat-form-field-appearance-standard {
      // Sets the border color when invalid
      &.mat-form-field-invalid .mat-form-field-flex {
        border-color: $xpo-red--400;
      }

      .mat-form-field-wrapper {
        height: unset;
        line-height: $xpo-lineHeight--small;
        padding: 0;
        top: 0;
      }

      .mat-form-field-subscript-wrapper {
        margin: 0;
        position: relative;
      }

      .mat-hint {
        font-size: $xpo-fontSize--small;
        margin-top: $xpo-contentSpacing / 2;
      }

      .mat-error {
        display: flex;
        font-family: $xpo-fontFamily--primary;
        font-size: $xpo-fontSize--medium;
        font-weight: $xpo-fontWeight--normal;
        line-height: $xpo-lineHeight--small;
        margin-top: $xpo-contentSpacing / 2;

        .mat-icon {
          display: inline-flex;
          font-size: $_formField-icon-fontSize;
          height: auto;
          line-height: $xpo-lineHeight--small;
          margin-right: $xpo-contentSpacing / 4;
          width: auto;
        }
      }

      // Removes the underline
      .mat-form-field-underline {
        display: none;
      }
    }

    &:not(.mat-form-field-has-label) {
      margin-top: 0;
    }

    &.mat-focused {
      .mat-form-field-flex {
        border: $ngx-input-border-width solid $xpo-black;
      }

      .mat-form-field-wrapper {
        @include mat-elevation(0);
      }
    }

    .mat-form-field-flex {
      $_formField-borderRadius: 2px;

      align-items: center;
      background-color: $xpo-white;
      border: $ngx-input-border-width solid $ngx-input-color-border;
      border-radius: $_formField-borderRadius;
      display: flex;

      .mat-form-field-suffix {
        $_formField-suffix-margin: 4px;

        margin-right: $_formField-suffix-margin;

        .mat-icon-button {
          height: auto;
          width: auto;
        }
      }

      .mat-form-field-infix {
        align-items: center;
        border: 0;
        display: flex;
        padding: 0;
        width: 208px;
      }
    }

    .mat-form-field-label {
      color: $xpo-grey--950 !important; // The reason of the !important is the blue color on focus
      font-family: $xpo-fontFamily--primary;
      font-size: $xpo-header-fontSize--large; //This size in 20px because the default label with scale(0.75)
      font-weight: normal;
      letter-spacing: normal;
      overflow: visible;

      mat-label {
        align-items: center;
        display: inline-flex;

        .mat-icon {
          padding-left: $xpo-contentSpacing / 2;
        }
      }
    }

    .mat-form-field-label-wrapper {
      top: $_label-top;
    }
  }

  // DATE RANGE INPUT
  .xpo-DateRangeInput {
    &-separator {
      // Disable text selection
      // we add this here, instead of the component styles definition
      // just to reuse material mixin we have available here and it's not available
      // inside the component styles definition
      @include user-select(none);
    }
  }

  // DATE PICKER POPUP
  .cdk-overlay-pane.xpo-DateRangePicker-popup {
    // the same as for `margin-top` in case the calendar is shown above the input
    margin-bottom: -1px;
    // this is a negative top margin used to show the calendar popup top border
    // and to not showing the input bottom border
    margin-top: -1px;
  }
}

@import '~@angular/material/theming';
// TODO: Rename this so it doesn't get confused with the page's header

@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;


@mixin dialog-header--primary() {
  $_ngx-HeaderFont--size: 26px;
  $_ngx-HeaderPadding--top: 38px;
  $_ngx-HeaderMargin--horizontal: 24px;
  $_ngx-HeaderTitle-color: #212121;
  color: $_ngx-HeaderTitle-color;

  display: flex;
  flex: 1;
  flex-direction: row;
  flex-shrink: 0;
  font-size: $_ngx-HeaderFont--size;
  font-weight: $xpo-fontWeight--bold;
  justify-content: space-between;
  line-height: $_ngx-HeaderFont--size;
  line-height: normal;
  margin: 0 $_ngx-HeaderMargin--horizontal;
  padding-bottom: $xpo_contentSpacing / 2;
  padding-top: $_ngx-HeaderPadding--top;
}


@mixin xpo-dialog-theme($theme) {
  @include mat-dialog-theme($theme);
  $_ngx-DialogSpacing--horizontal: 24px;
  $_ngx-DialogSpacing--contentBottom: 36px;

  mat-dialog-container.mat-dialog-container {
    border-radius: 0;

    [mat-dialog-title].mat-dialog-title {
      @include dialog-header--primary();

      text-transform: none;

      &:not(.xpo-ConfirmDialog-header) {
        border-bottom: 1px solid $xpo-grey--300;
      }
    }

    [mat-dialog-content].mat-dialog-content,
    mat-dialog-content.mat-dialog-content {
      margin: 0;
      padding: $xpo-contentSpacing/2 $_ngx-DialogSpacing--horizontal $_ngx-DialogSpacing--contentBottom;

      p:not(:last-of-type) {
        // The design specifies 24px margin bottom from the border of the letter,
        // to achieve that wee need to reduce the value of that margin
        margin-bottom: $xpo-contentSpacing;
      }
    }

    [mat-dialog-actions].mat-dialog-actions,
    mat-dialog-actions.mat-dialog-actions {
      border-top: 1px solid $xpo-grey--300;
      justify-content: space-between;
      margin: 0 $_ngx-DialogSpacing--horizontal;
      padding: $_ngx-DialogSpacing--horizontal 0;

      .xpo-ButtonGroup > .mat-button-base {
        margin-left: 0;
      }
    }
  }
}

// This overrides styles in order for the expansion-panel to match the
// ngx-ltl-core design guidelines.
@mixin xpo-expansion-panel-theme($theme) {
  @include mat-expansion-panel-theme($theme);

  $_matExpansion-headerTitle-paddingLeft: 10px;
  $_panelMatIcon-fontSize: 20px;
  $_matExpansionPanelBody-padding: $xpo-contentSpacing / 2 39px $xpo-contentSpacing / 2;
  $_matExpansionPanelHeader-StatusIndicator-fontSize: 10px;

  .mat-accordion > .mat-expansion-panel,
  .mat-expansion-panel {
    // Fix scrolling when opening panel and other panel is already opened
    margin: 0.1px;

    &,
    &:not([class*='mat-elevation-z']) {
      border-radius: 0;
      box-shadow: none;
    }

    & {
      .mat-expansion-panel-header {
        padding: $xpo-contentSpacing / 2;
      }
    }

    .mat-icon {
      font-size: $_panelMatIcon-fontSize;
    }

    .mat-expansion-panel-body {
      padding: $_matExpansionPanelBody-padding;
      padding-bottom: $xpo-contentSpacing / 2;

      p {
        font-size: $xpo-fontSize--root;
      }
    }

    .mat-expansion-panel-content {
      .mat-cell {
        > .mat-icon {
          margin-right: $xpo-contentSpacing / 2;
        }
      }
    }

    .mat-content {
      align-items: center;

      .mat-expansion-panel-header-title {
        margin-right: 0;
        padding: $xpo-contentSpacing / 2 $_matExpansion-headerTitle-paddingLeft;
      }
    }

    &:not(.mat-expanded) {
      .mat-content::before {
        content: 'add';
        font-family: 'Material Icons';
        font-size: $xpo-lineHeight--base;
      }
    }

    &.mat-expanded {
      .mat-content::before {
        content: 'remove';
        font-family: 'Material Icons';
        font-size: $xpo-lineHeight--base;
      }
    }

    &:not(.xpo-ExpansionPanelDark--wrap) {
      border-bottom: 1px solid $xpo-grey--300;

      mat-expansion-panel-header {
        &.mat-expansion-panel-header {
          font-size: $xpo-header-fontSize--small;

          &:not([aria-disabled='true']) {
            .mat-expansion-panel-header-title {
              color: $xpo-black;
            }

            .mat-content::before {
              color: $xpo-grey--930;
            }
          }

          &[aria-disabled='true'] {
            color: $xpo-grey--300;
          }
        }
      }
    }

    &.xpo-ExpansionPanelDark--wrap {
      margin-bottom: $xpo-contentSpacing / 2;

      &:not(.mat-expanded) {
        border: 1px solid $xpo-grey--300;
      }

      &.mat-expanded {
        .mat-expansion-panel-content {
          border: 1px solid $xpo-grey--300;
          border-top: none;
        }
      }

      .mat-expansion-panel-header {
        padding: 0;
      }

      .mat-expansion-panel-header {
        &[aria-disabled='true'] {
          .mat-content {
            &::before {
              color: $xpo-grey--80 !important;
            }

            .mat-expansion-panel-header-title {
              color: $xpo-grey--300;
            }
          }
        }

        &.mat-expanded {
          .mat-content::before {
            background-color: $xpo-grey--950;
            color: $xpo-white;
            margin-right: 0;
            padding: $xpo-contentSpacing / 2;
          }

          .mat-content {
            background-color: $xpo-grey--900;
            display: inline-flex;

            & > .mat-expansion-panel-header-title,
            .mat-expansion-panel-header-description {
              color: $xpo-white;
            }
          }
        }

        &:not(.mat-expanded) {
          .mat-content {
            background-color: $xpo-grey--80;
            color: $xpo-grey--1000;
            display: inline-flex;

            &::before {
              background-color: $xpo-grey--300;
              color: $xpo-grey--950;
              margin-right: 0;
              padding: $xpo-contentSpacing / 2;
            }
          }
        }
      }
    }

    mat-expansion-panel-header {
      &.mat-expansion-panel-header {
        ::before,
        .mat-icon,
        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
          align-items: center;
          display: flex;
        }

        ::before {
          margin-right: $xpo-contentSpacing / 2;
        }

        .mat-expansion-panel-header-title {
          @include TextTreatment-header--small();

          flex-grow: 0;
          line-height: $xpo-lineHeight--base;
        }

        .mat-expansion-panel-header-description {
          @include TextTreatment--small();

          flex-grow: 0;
        }

        .xpo-StatusIndicator {
          &:not(:first-of-type) {
            margin-left: $xpo-contentSpacing / 2;
          }

          &-tag {
            font-size: $_matExpansionPanelHeader-StatusIndicator-fontSize;
          }
        }
      }
    }

    &.mat-expanded {
      &.mat-expansion-panel-spacing {
        margin: 0;
      }
    }

    &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background: none;
    }
  }
}

///
// The overrides in order for the form field to match the
//   design specifications.
// As a note: This does not contain the styles for the inputs
//   themselves. Those can be found in the input theme. While
//   most of the styling for inputs is here, technically it's
//   the "form-field" that is being styled here.
//
// Additional Note: Please continue to use EMs for sizing.
//   This allows the inputs to be scaled outside of the
//   components around it. This is what Material does and
//   it has been kept functioning by keeping the trend.
//
// @author    Peter Ramsing <peter.ramsing@xpo.com>
//
// @thanks    Allison, Peter's wife, who spent a lonely
//             weekend while he coded this in the office.
//
// TODO: Figure out the @at-root organization here. The
//        compilation is a bit wonky.
///

/*
    1. Thanks to sub-pixels and how they render this caused some nasty
        blur. Ew! What one can do is start to add sub-pixels in along
        with the "em" (or rem, or points, or 🧀; it doesn't really
        matter). This is sub-pixel pushing so I just used my eye on
        a low-rez monitor and got it as close as I could. Because
        `calc` was uses this will be handled on the DOM instead of
        during the SCSS compilation which should give the rendering
        engine the best chance to make the correct decision.
    2. This is to support the text weight changing from the label
        changing weights as we want to animate this change instead
        of a snapping change.
    3. This large bit of classes is to identify if a prefix is attached
        to the input so that we can move the label better.
    4. Material has this set to 133.333% because they scale the label.
        We're changing that scale so we need to change the size of it.
*/

@mixin xpo-form-field-theme($theme) {
  @include mat-form-field-theme($theme);

  // INPUT STYLE
  mat-form-field.mat-form-field-type-mat-input {
    $_label-top: -24px;
    $_formField-marginTop: 24px;
    $_defaultFormFieldWrapper-top: -12px;
    $_defaultFormFieldWrapper-left: 9px;
    $_form-field-borderRadius: 2px;
    $_formField-suffixPrefix-margin: 6px;
    $_formField-icon-size: 16px;
    $_formField-icon-fontSize: 20px;
    $_formField-suffixPrefix-loader--size: 24px;

    margin-bottom: $xpo-contentSpacing;
    margin-top: $_formField-marginTop;

    &.mat-form-field-appearance-standard {
      .mat-form-field-flex {
        align-items: center;
        padding-top: 0;
      }
    }

    // Input default behavior
    &.mat-form-field-appearance-legacy {
      // Only placeholder
      &.mat-form-field-hide-placeholder {
        .mat-form-field-label {
          font-size: $xpo-fontSize--medium;
          letter-spacing: normal;
          opacity: 0.5;
        }

        .mat-form-field-label-wrapper {
          left: $_defaultFormFieldWrapper-left;
          overflow: visible;
          top: $_defaultFormFieldWrapper-top;
        }

        // Focused or with value
        &.mat-form-field-should-float {
          .mat-form-field-label-wrapper {
            left: 0;
            top: $_label-top;
          }

          .mat-form-field-label {
            font-family: $xpo-fontFamily--primary;
            font-size: $xpo-header-fontSize--medium; //This size in 18px because the default label with scale(0.75)
            font-weight: normal;
            letter-spacing: inherit;
            opacity: 1;
          }
        }
      }
    }

    &.mat-form-field-appearance-legacy,
    &.mat-form-field-appearance-standard {
      $_innerInput-leftPadding: 0.25em;
      $_innerInput-rightPadding: 0.25em;
      $_input-borderColor--focused: $ngx-input-color-onActive--dark;

      // Sets the border color when invalid
      &.mat-form-field-invalid .mat-form-field-flex {
        border-color: $xpo-color--error;
      }

      .mat-form-field-wrapper {
        height: unset;
        line-height: $xpo-lineHeight--small;
        padding: 0;
        top: 0;
      }

      &.ngx-FormField--inline {
        &,
        .mat-form-field-wrapper {
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .mat-form-field-subscript-wrapper {
        margin: 0;
        position: relative;
      }

      .mat-hint {
        font-size: $xpo-fontSize--small;
        margin-top: $xpo-contentSpacing / 2;
      }

      .mat-error {
        display: flex;
        font-family: $xpo-fontFamily--primary;
        font-size: $xpo-fontSize--medium;
        font-weight: $xpo-fontWeight--normal;
        line-height: $xpo-lineHeight--small;
        margin-top: $xpo-contentSpacing / 2;

        .mat-icon {
          display: inline-flex;
          font-size: $_formField-icon-fontSize;
          height: auto;
          line-height: $xpo-lineHeight--small;
          margin-right: $xpo-contentSpacing / 4;
          width: auto;
        }
      }

      // Borderless
      // This class has the old format, we're not updating now because it might being
      // used in several applications
      &.ngx-FormField--borderless {
        .mat-form-field-flex {
          border: none;
          box-shadow: unset;
        }
      }

      // REMOVALS
      // Removes the underline
      .mat-form-field-underline {
        display: none;
      }
    }

    &:not(.mat-form-field-has-label) {
      margin-top: 0;
    }

    &.mat-focused {
      .mat-form-field-flex {
        border: $ngx-input-border-width solid $xpo-black;
      }

      .mat-form-field-wrapper {
        @include mat-elevation(0);
      }
    }

    .mat-form-field-flex {
      align-items: center;
      background-color: $xpo-white;
      border: $ngx-input-border-width solid $ngx-input-color-border;
      border-radius: $_form-field-borderRadius;
      display: flex;

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        display: flex;

        .mat-icon {
          display: inline-flex;
          font-size: $_formField-icon-fontSize;
          height: $_formField-icon-size;
          justify-content: center;
          line-height: $_formField-icon-size;
          width: $_formField-icon-size;
        }

        .xpo-BusyLoader {
          width: $_formField-suffixPrefix-loader--size;
        }
      }

      .mat-form-field-prefix {
        margin-left: $_formField-suffixPrefix-margin;
      }

      .mat-form-field-suffix {
        margin-right: $_formField-suffixPrefix-margin;
      }

      .mat-form-field-infix {
        align-items: center;
        border: 0;
        display: flex;
        padding: 0;

        .mat-icon + .mat-input-element {
          padding-left: 0;
        }

        .mat-input-element {
          color: $xpo-black;
          font-family: $xpo-fontFamily--primary;
          font-size: $xpo-fontSize--medium;
          margin: 0;
          padding: 6px $xpo-contentSpacing/2;
          resize: none;

          &:disabled {
            background-color: $xpo-grey--100;
            color: $xpo-black;
            font-style: italic;
            font-weight: $xpo-fontWeight--normal;
          }
        }
      }
    }

    .mat-form-field-label {
      color: $xpo-grey--950 !important; // The reason of the !important is the blue color on focus
      font-family: $xpo-fontFamily--primary;
      font-size: $xpo-header-fontSize--large; //This size in 20px because the default label with scale(0.75)
      font-weight: normal;
      letter-spacing: normal;
      overflow: visible;

      mat-label {
        align-items: center;
        display: inline-flex;

        .mat-icon {
          padding-left: $xpo-contentSpacing / 2;
        }
      }
    }

    .mat-form-field-label-wrapper {
      top: $_label-top;
    }
  }

  // XPO custom Components/Directives
  .xpo-Label--wrap {
    .mat-checkbox-layout,
    .mat-radio-label {
      white-space: unset;
    }
  }

  .xpo-Form--inline {
    align-items: flex-end;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;

    .mat-button-base,
    .mat-button-toggle-group,
    .mat-form-field,
    .mat-form-field-type-mat-input:not(.mat-form-field-has-label) {
      margin-bottom: $xpo-contentSpacing;
      margin-right: $xpo-contentSpacing / 2;
      width: auto;
    }

    & > .mat-button-toggle {
      margin-bottom: $xpo-contentSpacing;

      &:last-of-type {
        margin-right: $xpo-contentSpacing / 2;
      }
    }
  }
}

///
// The overrides in order for the Input to match the design
//
// As a note: This is just for Inputs. Most of the styling
//   for inputs is actually handled on the Form Field.
//
// Additional Note: Please continue to use EMs for sizing.
//   This allows the inputs to be scaled outside of the
//   components around it. This is what Material does and
//   it has been kept functioning by keeping the trend.
//
// @author    Peter Ramsing <peter.ramsing@xpo.com>
///

@mixin xpo-input-theme($theme) {
  @include mat-input-theme($theme);

  input.mat-input-element {
    caret-color: $xpo-grey--900 !important;
    margin-top: 0.1375em;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

///
// The code to make the base Material Menus make Brad happy
//
// ...and Mario, too.
//
// @author    Peter Ramsing <peter.ramsing@xpo.com>
//
///

/*
    1. Threw a `div` to start this off to get the score to 0011 in order
        to beat Materials 0010. Mwahahaha.
    2. This disables the caret on anything but the first menu when there
        are nested menus. This is slightly fragile but sadly Material
        doesn't give us much of a choice here. We know the first panel
        is the second child of the overlay `div` so we get the second
        with `nth-of-type(2)` which gets the second `div`...then add
        `:not()` and you now get "everything that isn't the second div.
    3. This is because the first and last menu items have space added
        around them. The carets need to respect that and because the
        area round the buttons needs to accept the hover state this
        these need to be manually moved. All other carets are just
        moved 50% wholesale.
    4. Thanks to the reduced padding we need to move the submenu trigger
        icon over just a bit.
*/

@mixin xpo-menu-theme($theme) {
  @include mat-menu-theme($theme);

  div.mat-menu-panel {
    // #1
    $_caret-size: 0.4em;
    $_menu-height: 1.75em;
    $_topBottom-padding: 0.5em;

    border-radius: 0;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), -1px -1px 1px -1px rgba(0, 0, 0, 0.4), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    margin-top: 2px;
    min-height: unset; // overriding material's 64px
    overflow: visible;
    position: relative;

    .mat-menu-content:not(:empty) {
      padding-bottom: 0;
      padding-top: 0;
    }

    .mat-menu-item {
      color: $xpo-grey--700;
      height: $_menu-height;
      line-height: $_menu-height;
      padding: 0 $xpo-contentSpacing / 2;

      &:disabled {
        color: $xpo-grey--400;
        cursor: not-allowed;
      }

      &-submenu-trigger::after {
        margin-right: -7px; // #4
      }
    }

    a.mat-menu-item,
    a.mat-menu-item:hover,
    a.mat-menu-item:focus {
      text-decoration: none;
    }

    .mat-divider {
      color: $xpo-grey--300;

      &:last-child {
        display: none;
      }
    }
  }

  // #2
  .cdk-overlay-connected-position-bounding-box:not(:nth-of-type(2)) {
    .mat-menu-panel::after {
      display: none;
    }
  }
}

@mixin snackbar--width--breakpoint() {
  @media (max-width: 768px) {
    // tablet landscape
    min-width: 80vw;
    max-height: 40vh;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    // tablet portrait
    min-width: 60vw;
    width: auto;
    max-height: 40vh;
  }
  @media (min-width: 1024px) {
    // desktop
    min-width: 40vw;
    max-height: 40vh;
  }
}

@mixin snackBar--background($name, $background-color) {
  &--#{$name} {
    background-color: $background-color;
  }
}

@mixin snackBar--text--color($name, $color) {
  &--#{$name} {
    color: $color;

    .xpo-Link {
      color: $color;
      font-weight: $xpo-fontWeight--normal;
    }
  }
}

@mixin snackBar--close--color($name, $color) {
  &--#{$name} &-closeButton {
    color: $color;

    &:hover {
      color: $xpo-white;
    }
  }
}

@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;


@mixin xpo-snack-bar-theme($theme) {
  @include mat-snack-bar-theme($theme);

  snack-bar-container.mat-snack-bar-container {
    $_snackBar-borderRadius: 2px;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);

    background: $xpo-white;
    border-radius: $_snackBar-borderRadius;
    color: $xpo-fontColor--primary;
    padding: 0;
    @include snackbar--width--breakpoint();
    overflow: hidden;
    margin: $xpo-contentSpacing * 2;
  }
}

@mixin xpo-table-theme($theme) {
  @include mat-table-theme($theme);

  $is-dark-theme: map-get($theme, is-dark); // TODO: for all of these variables, it should come from the palette
  $_table-spacing: $xpo-contentSpacing / 2;
  $_table-borderColor: $xpo-grey--300;
  $_table-border: 1px solid $_table-borderColor;
  $_footer-backgroundColor: if($is-dark-theme, $xpo-grey--700, $xpo-grey--200);
  $_header-groupBackgroundColor: if($is-dark-theme, $xpo-grey--900, $xpo-grey--200);
  $_header-backgroundColor: if($is-dark-theme, $xpo-grey--700, $xpo-grey--80);
  $_header-color: if($is-dark-theme, white, $xpo-grey--900);

  // Wrapping all styles around `.mat-table` to increase specificity so
  // these styles take persistance over material's
  .mat-table {
    border: $_table-border;

    tr.mat-header-row,
    tr.mat-footer-row,
    tr.mat-row {
      height: $ngx-table-row-height;
    }

    mat-header-row.mat-header-row,
    mat-footer-row.mat-footer-row,
    mat-row.mat-row {
      min-height: $ngx-table-row-height;
    }

    // Border Bottom elements
    mat-row.mat-row,
    mat-header-row.mat-header-row,
    mat-footer-row.mat-footer-row,
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
      border-bottom-color: $_table-borderColor;
    }

    // Remove border bottom color from last row to avoid double border
    mat-row.mat-row:last-child,
    mat-footer-row.mat-footer-row:last-child,
    tr.mat-row:last-child > .mat-cell {
      border-bottom-color: transparent;
    }

    .mat-cell {
      .mat-icon {
        align-items: center;
        justify-content: center;
      }

      > .xpo-Icon {
        margin-right: $xpo-contentSpacing / 4;
      }
    }
    // All Cells
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell,
    mat-cell.mat-cell,
    mat-header-cell.mat-header-cell,
    mat-footer-cell.mat-footer-cell {
      &,
      &:first-of-type {
        // needed to override material's styles
        padding-left: $_table-spacing;
      }

      &,
      &:last-of-type {
        // needed to override material's styles
        padding-right: $_table-spacing;
      }

      font-size: $xpo-fontSize--medium;
    }

    // Header rows
    tr.mat-header-row,
    mat-header-row.mat-header-row {
      background-color: $_header-backgroundColor;
      box-shadow: 0 4px -2px -1px rgba(0, 0, 0, 0.1);

      .mat-header-cell {
        color: $_header-color;
        font-size: $xpo-fontSize--small;
        font-weight: $xpo-fontWeight--medium;
      }
    }

    // Footer Rows
    tr.mat-footer-row,
    mat-footer-row.mat-footer-row {
      background-color: $_footer-backgroundColor;

      .mat-footer-cell {
        font-weight: $xpo-fontWeight--medium;
      }
    }

    .mat-tab-label.mat-tab-label-active {
      color: $xpo-black;
    }
  }

  .mat-cell {
    .mat-icon {
      align-items: center;
      justify-content: center;
    }

    > .xpo-Icon {
      margin-right: $xpo-contentSpacing / 4;
    }
  }

  // Xpo Custom Class
  .xpo-MatTable {
    // Column Group
    &--withColumnGroup {
      // Primary header
      tr.mat-header-row:first-of-type {
        background-color: $_header-groupBackgroundColor;

        th.mat-header-cell {
          text-align: center;
        }
      }
    }

    // Reusable Classes
    &--borderRight {
      border-right: $_table-border;
    }

    &--borderLeft {
      border-left: $_table-border;
    }

    // Cell Classes
    &-cell {
      &--textAlignRight {
        &.mat-header-cell,
        &.mat-cell,
        &.mat-footer-cell {
          justify-content: flex-end;
          text-align: right;
        }
      }

      &--textAlignCenter {
        &.mat-header-cell,
        &.mat-cell,
        &.mat-footer-cell {
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}

@mixin xpo-tabs-theme($theme) {
  @include mat-tabs-theme($theme);
  $_tab-border: 1px solid $xpo-grey--300;
  $_tab-ink-thickness: 4px;
  $_tab-innerPadding: 12px;
  // Paging arrows are drawn with an empty div that has 2px border. Using the translate option it achieves
  // the arrow form. This value represents the height/width of the div and is applied in order to make the
  // arrow 16px x 16px as it is defined in the UX specs.
  $_tab-nav-size: 11.34px;
  $_tab-wider-header-pagination-max-width: 40px;
  $_tab-header-pagination-max-width: 24px;

  .mat-tab-header-pagination {
    background-color: $xpo-grey--80;
    border: $_tab-border;
    box-shadow: none;
    max-width: $_tab-header-pagination-max-width;
    min-width: 0 !important;
    width: 29px;

    :not(.xpo-Tabs-wideNav) & {
      max-width: $_tab-header-pagination-max-width;
    }

    .xpo-Tabs-wideNav & {
      max-width: $_tab-wider-header-pagination-max-width;
      width: 55px;
    }

    .mat-tab-header-pagination-chevron {
      height: $_tab-nav-size;
      width: $_tab-nav-size;
    }
  }

  .mat-tab-label-content {
    padding-bottom: 0 !important;

    span {
      line-height: normal;
    }

    .xpo-icon,
    .mat-icon,
    .mat-chip,
    .mat-chip-list-wrapper {
      margin-left: $xpo-contentSpacing / 2;
    }

    .mat-chip.xpo-Chip--small {
      $_matChip-font-size: 11px;

      @include chip-size($xpo-lineHeight--small, $_matChip-font-size);

      .mat-icon {
        @include icon-size($_matChip-font-size);
      }
    }

    & > .xpo-icon:first-child,
    & > .mat-icon:first-child,
    & > .mat-chip:first-child {
      margin-left: $_tab-innerPadding;
    }

    & > .mat-icon {
      align-items: center;
      display: flex;
      font-size: $xpo-fontSize--large;
      width: auto;
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $xpo-blue--300;
  }

  .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    color: $xpo-black;
  }

  .mat-tab-labels .mat-tab-label,
  .mat-tab-nav-bar .mat-tab-link {
    color: $xpo-black;
    height: $ngx-tab-header-height;
    line-height: $ngx-tab-header-height;
    margin-left: 0;
    min-width: 0;
    opacity: 1; // Overriding material's 0.6 opacity
    padding: $xpo-contentSpacing / 4 $xpo-contentSpacing / 2;

    &:first-child {
      margin-left: 0;
    }

    &:hover,
    &.mat-tab-label-active {
      color: $xpo-fontColor--primary;
    }

    &.mat-tab-disabled {
      color: $xpo-grey--300;
    }
  }

  .mat-tab-link {
    // Removing underline applied to a tags from the xpo-button styles
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  /*
   * Most of our cases we have the tab contents take up the whole space of the view,
   *  it hard to access this selector with view encapsulation on, so we can default it to
   *  100%. the user can always wrap the tabs in a wrapper and dictate the height there.
   */

  .mat-tab-body-wrapper {
    height: 100%;
  }

  .xpo-PrimaryTabs,
  .xpo-AlternateTabs {
    &.mat-tab-nav-bar,
    &.mat-tab-group {
      .mat-ink-bar {
        display: none;
      }

      // Removing the border bottom to put it on the label so that tab can overlap the border
      // bottom when selected
      &,
      .mat-tab-header {
        border-bottom: none;
      }

      .mat-tab-list {
        border-bottom: $_tab-border;
      }

      .mat-tab-header .mat-tab-label,
      .mat-tab-links .mat-tab-link {
        background-color: $xpo-white;
        border-bottom: none;
        border-left: $_tab-border;
        border-top: $_tab-border;
        margin-left: 0;
        padding: 0 $_tab-innerPadding;

        &:last-child {
          border-right: $_tab-border;
        }

        &:first-child {
          margin-left: 0;
        }

        &.mat-tab-label-active {
          border-top: $_tab-ink-thickness solid $xpo-blue--850;
          overflow: inherit;

          .mat-tab-label-content {
            margin-bottom: $_tab-ink-thickness - 1;
          }

          .mat-chip {
            background-color: $xpo-grey--600;
            color: white;
          }

          // Overlapping the border bottom of the tab-list
          &::after {
            background-color: white;
            bottom: -1px;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
          }
        }

        &:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) {
          border-top: $_tab-ink-thickness solid $xpo-blue--350;

          .mat-tab-label-content {
            margin-bottom: $_tab-ink-thickness - 1;
          }
        }
      }
    }
  }
}

///
// Overrides for the Select
//
// @author: Peter Ramsing <peter.ramsing@xpo.com>
///

/*
    1. Moves the select to sit right under the trigger
*/

@mixin xpo-select-theme($theme) {
  @include mat-select-theme($theme);
  $_option-height: 2.3em;
  $_select-twoLinesDescription-fontSize: 11px;
  $_selectPanel-marginLeft: 7px;
  $_selectPanel-marginTop: 24px;
  $_selectInput-padding--vertical: 6px;
  $_form-field-borderRadius: 2px;
  $_form-field-suffix-loader--size: 24px;
  $_form-field-suffix-right: 24px;
  $_formField-icon-size: 16px;
  $_formField-icon-fontSize: 20px;

  // Select with icon and two lines
  .xpo-Select-twoLinesContainer {
    color: $xpo-grey--1100;
    display: flex;
    flex-direction: column;
    margin-left: $xpo-contentSpacing / 2;

    .mat-option-text {
      &:nth-of-type(1) {
        color: $xpo-black;
        line-height: $xpo-lineHeight--small;
      }

      &:nth-of-type(2) {
        color: $xpo-grey--950;
        font-size: $_select-twoLinesDescription-fontSize;
        line-height: 12px;
        padding-top: $xpo-contentSpacing / 4;
      }
    }
  }

  .xpo-Select-twoColumnsContainer {
    display: flex;
    width: 100%;

    span:first-of-type {
      flex: 1;
    }

    span:last-of-type {
      flex: 2;
    }
  }

  .xpo-Select-panel {
    &.mat-select-panel {
      border: 1px solid $ngx-input-color-border;
      border-radius: 0;
      border-top: none;
      box-shadow: none;
      margin-left: $_selectPanel-marginLeft; // #1
      margin-top: $_selectPanel-marginTop; // #1

      .mat-option,
      .mat-optgroup-label {
        height: $xpo-input-height--default; // The style is overwritten by default otherwise
        line-height: normal; // The style is overwritten by default otherwise
        padding: $_selectInput-padding--vertical $xpo-contentSpacing / 2;

        &:not(:last-of-type) {
          border-bottom: $ngx-input-border-width solid $xpo-grey--300;
        }

        &.xpo-Option {
          &--twoLines {
            height: $xpo-input-height--twoLines;

            .mat-option-text {
              display: flex;
              flex-direction: row;
            }
          }

          &-hideDivider:not(:last-of-type) {
            border-bottom: none;
          }
        }

        &:hover:not(.mat-option-disabled),
        &:focus:not(.mat-option-disabled) {
          background: $xpo-select-backgroundColor-optionOnHover;
        }

        .mat-icon {
          margin-right: 0;
        }

        &.mat-selected {
          &:not(.mat-option-disabled) {
            background: $xpo-select-backgroundColor-optionOnHover;
            color: $xpo-grey--900;
          }
        }

        &-disabled {
          background-color: $xpo-grey--80;
        }

        .mat-option-text {
          align-items: center;
          color: $xpo-grey--950;
        }

        .mat-pseudo-checkbox {
          border: 1px solid $ngx-input-color-border;
          margin-right: 6px;

          &-disabled {
            background-color: $xpo-grey--300;
            border-color: $xpo-grey--300;

            &::after {
              border-left: 2px solid currentColor;
              box-sizing: content-box;
              height: 3px;
              opacity: 1;
              transform: rotate(-45deg);
              // For some strange reason Angular Material doesn't have these styles
              // for the disabled options, we are adding it to ensure that we can show
              // selected options as disabled.
              width: 8px;
            }
          }

          &-checked {
            background-color: $xpo-black;
            border-color: $xpo-black;
          }

          &::after {
            // These values are needed to override the ones set by Material,
            // we need to override them because we changed the border-with.
            left: 2px;
            top: 3.4px;
          }
        }
      }

      // Applying these styles outside the mat-option block, so it
      // doesn't mixup or break the mat-option
      .mat-optgroup-label {
        border-bottom: $ngx-input-border-width solid $xpo-grey--300;
        color: $xpo-grey--950;
        font-weight: $xpo-fontWeight--bold;
      }

      .mat-optgroup {
        &:not(:first-of-type) .mat-optgroup-label {
          border-top: $ngx-input-border-width solid $xpo-grey--300;
        }
      }
    }
  }

  // Removals
  .mat-option .mat-option-ripple {
    display: none !important;
  }

  .mat-form-field {
    &.mat-form-field-type-mat-select {
      &.mat-form-field-appearance-standard {
        .mat-form-field-flex {
          align-items: center;
          padding-top: 0;
        }
      }
    }

    &.mat-form-field-type-mat-select {
      &.mat-form-field-appearance-legacy,
      &.mat-form-field-appearance-standard {
        $is-dark-theme: map-get($theme, is-dark); // TODO: variables using this should come from palette
        $_input-borderColor--focused: $ngx-input-color-onActive--dark;
        $_input-borderColor: $ngx-input-color-border;
        $_labelVerticalMovement: -1.3em;
        $_prefixSuffixIcon-size: 2em;
        $_input-placeholderTextColor: if($is-dark-theme, $xpo-grey--100, $xpo-grey--400);
        $_input-labelTextColor: if($is-dark-theme, $xpo-grey--50, $xpo-grey--700);
        $_select-formFieldWrapper-top: -43px;
        $_formField-marginTop: 24px;

        margin-bottom: $xpo-contentSpacing;
        margin-top: $_formField-marginTop;

        &.mat-form-field-disabled {
          .mat-form-field-wrapper .mat-form-field-infix {
            background-color: $xpo-grey--80;
            cursor: not-allowed;
            font-style: italic;
          }

          .mat-select-placeholder,
          .mat-select-trigger {
            color: $xpo-black;
            cursor: not-allowed;
          }
        }

        &.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
          transform: none;
        }

        .mat-form-field-subscript-wrapper {
          margin: 0;
          position: relative;
        }

        .mat-form-field-wrapper {
          height: unset;
          line-height: $xpo-lineHeight--small;
          padding: 0;
          top: 0;

          // Sets the focused border color and box shadow
          @at-root .mat-focused .mat-form-field-infix {
            border-color: $_input-borderColor--focused !important;
          }

          // Sets the border color when invalid
          @at-root .mat-form-field-invalid .mat-form-field-infix {
            border-color: $xpo-color--error !important;
          }

          .mat-form-field-infix {
            background-color: $xpo-white;
            border: $ngx-input-border-width solid $_input-borderColor;
            border-radius: $_form-field-borderRadius;
            padding: $_selectInput-padding--vertical $xpo-contentSpacing / 2;

            .mat-select-arrow {
              margin-right: 0;
            }

            .mat-select-value-text {
              color: $xpo-grey--1100;
            }
          }
        }

        &.mat-focused {
          .mat-select-arrow {
            color: $xpo-grey--900 !important;
          }
        }

        &.ngx-FormField--inline .mat-form-field-wrapper {
          margin-bottom: 0;
          margin-top: 0;
        }

        &.mat-form-field-invalid .mat-form-field-label {
          color: $_input-placeholderTextColor !important;
        }

        &:not(.mat-form-field-should-float) .mat-form-field-label {
          color: $_input-placeholderTextColor;
        }

        &.mat-form-field-should-float .mat-form-field-label {
          color: $xpo-grey--950;
        }

        .mat-form-field-label-wrapper {
          overflow: visible;
          right: $xpo-contentSpacing / 2;
          top: $_select-formFieldWrapper-top;
        }

        .mat-form-field-label {
          color: $_input-placeholderTextColor;
          font-size: $xpo-fontSize--medium;
          font-weight: $xpo-fontWeight--normal;
          letter-spacing: normal;
          max-width: 100% !important; // #4
          transform: unset !important;
        }

        &.mat-form-field-invalid .mat-form-field-label {
          color: $_input-placeholderTextColor !important;
        }

        &:not(.mat-form-field-should-float) .mat-form-field-label {
          color: $_input-placeholderTextColor;
        }

        // This is the asterisk that is added if `required` is added to the input
        .mat-placeholder-required {
          color: $xpo-color--error;
        }

        .mat-hint {
          font-size: $xpo-fontSize--small;
          font-style: italic;
          font-weight: $xpo-fontWeight--normal;
          line-height: $xpo-lineHeight--small;
        }

        .mat-error {
          display: flex;
          font-family: $xpo-fontFamily--primary;
          font-size: $xpo-fontSize--medium;
          font-weight: $xpo-fontWeight--normal;
          line-height: $xpo-lineHeight--small;
          margin-top: $xpo-contentSpacing / 2;

          .mat-icon {
            display: inline-flex;
            font-size: $_formField-icon-fontSize;
            height: auto;
            line-height: $xpo-lineHeight--small;
            margin-right: $xpo-contentSpacing / 4;
            width: auto;
          }
        }

        .mat-form-field-prefix {
          padding-left: 0.3em;
        }

        .mat-form-field-suffix {
          height: $_form-field-suffix-loader--size;
          position: absolute;
          right: $_form-field-suffix-right;
          width: $_form-field-suffix-loader--size;
        }

        // Borderless
        &.ngx-FormField--borderless {
          .mat-form-field-wrapper {
            border: none;
            box-shadow: unset;
          }
        }

        // REMOVALS
        // Removes the underline
        .mat-form-field-underline {
          display: none;
        }
      }
    }
  }

  // These styles remove the extra width and the translateX inline styles,
  // added by Angular Material using variables SELECT_PANEL_PADDING_X
  // and SELECT_MULTIPLE_PANEL_PADDING_X.
  // Although SELECT_MULTIPLE_PANEL_PADDING_X is public and right now we could
  // override it, it's marked as deprecated, so we are applying this solution that
  // will be valid up to Angular12.
  .xpo-Select-panel.mat-select-panel {
    min-width: calc(100% + 18px) !important;
  }

  .xpo-Select-panelMultiple.mat-select-panel {
    margin-left: 31px;
  }
}

@mixin xpo-slide-toggle-theme($theme) {
  $_thumbSize: 22px;

  mat-slide-toggle.mat-slide-toggle {
    $_matSlideToggle-width-small: 32px;
    $_matSlideToggle-height-small: 16px;
    $_matSlideToggle-thumb-small: 14px;
    $_matSlideToggle-thumb: 20px;
    $_matSlideToggle-bar-with: 38px;
    $_matSlideToggle-border-unselected-disabled: #e2e2e4;
    $_matSlideToggle-border-checked-disabled: #c7dec6;
    $_matSlideToggle-background-bar: #cde8cc;

    &.mat-disabled {
      opacity: unset;
    }

    &.mat-checked {
      opacity: unset;
    }

    &.mat-checked.mat-disabled .mat-slide-toggle-bar {
      background-color: $_matSlideToggle-background-bar;
      border: 1px solid $_matSlideToggle-border-checked-disabled;
    }

    &.mat-disabled .mat-slide-toggle-bar {
      background-color: $xpo-brand-light-grey--50;
      border: 1px solid $_matSlideToggle-border-unselected-disabled;

      .mat-slide-toggle-thumb {
        box-shadow: 0 0 0 1px $_matSlideToggle-border-unselected-disabled;
      }
    }

    &.mat-checked .mat-slide-toggle-bar {
      background-color: $xpo-green--450;
      border: 1px solid $xpo-green--600;
    }

    .mat-slide-toggle-bar {
      background-color: $xpo-grey--80;
      border: 1px solid $xpo-grey--300;
      border-radius: 30px;
      height: $_thumbSize;
      width: $_matSlideToggle-bar-with;
    }

    &.mat-checked.mat-disabled .mat-slide-toggle-thumb {
      box-shadow: 0 0 0 1px $_matSlideToggle-border-checked-disabled;
    }

    &.mat-checked .mat-slide-toggle-thumb {
      background-color: $xpo-white;
      border: none;
      box-shadow: none;
    }

    &.mat-disabled .mat-slide-toggle-thumb {
      background-color: $xpo-white;
    }

    .mat-slide-toggle-thumb-container {
      cursor: pointer;
      left: 0;
      top: 0.1px;

      &.mat-slide-toggle-thumb {
        background-color: $xpo-white;
        height: $_thumbSize;
        width: $_thumbSize;
      }
    }

    .mat-slide-toggle-thumb {
      background-color: $xpo-white;
      box-shadow: 0 0 0 1px $xpo-grey--300;
    }

    &.xpo-SlideToggle--small {
      &.mat-checked.mat-disabled .mat-slide-toggle-bar {
        background-color: $_matSlideToggle-background-bar;
        border: 1px solid $_matSlideToggle-border-checked-disabled;
      }

      &.mat-disabled .mat-slide-toggle-bar {
        background-color: $xpo-brand-light-grey--50;
        border: 1px solid $_matSlideToggle-border-unselected-disabled;
      }

      &.mat-checked .mat-slide-toggle-bar {
        background-color: $xpo-green--450;
        border: 1px solid $xpo-green--600;
      }

      .mat-slide-toggle-bar {
        background-color: $xpo-grey--80;
        border-radius: 30px;
        height: $_matSlideToggle-height-small;
        width: $_matSlideToggle-width-small;
      }

      .mat-slide-toggle-thumb-container {
        height: $_matSlideToggle-thumb-small;
        width: $_matSlideToggle-thumb-small;
      }

      .mat-slide-toggle-thumb {
        height: $_matSlideToggle-thumb-small;
        width: $_matSlideToggle-thumb-small;
      }
    }

    .mat-slide-toggle-ripple {
      display: none;
    }
  }
}

///
// Overrides for the radio button
///

@mixin xpo-radio-theme($theme) {
  @include mat-radio-theme($theme);

  mat-radio-button.mat-radio-button {
    .mat-radio-container {
      transform: scale(0.8);

      .mat-radio-inner-circle {
        background-color: $ngx-input-color-onActive--dark;
      }

      .mat-radio-outer-circle {
        border-width: $ngx-input-border-width;
      }
    }

    .mat-radio-label-content {
      padding-left: 2px;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $ngx-input-color-onActive--light;
      }

      .mat-radio-inner-circle {
        transform: scale(0.625);
      }
    }

    &.mat-radio-disabled {
      .mat-radio-label {
        cursor: not-allowed;
      }

      .mat-radio-outer-circle,
      &.mat-radio-checked .mat-radio-outer-circle {
        border-color: $ngx-input-color-disabled--dark;
      }

      .mat-radio-inner-circle {
        background-color: $ngx-input-color-disabled--dark;
      }
    }

    ///
    // Removals
    ///
    .mat-ripple {
      display: none;
    }
  }
}

@mixin xpo-header-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .xpo-Header {
    background: mat-color($background, app-bar);
    border-bottom: if($is-dark-theme, 2px, 1px) solid if($is-dark-theme, transparent, $xpo-grey--300);
  }
}

@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;


.xpo-Footer {
  $_footerLink-margin: 0 12px;

  width: 100%;

  &-navbar {
    :nth-child(n) {
      margin: $_footerLink-margin;

      &:last-child {
        margin-right: $xpo-contentSpacing;
      }
    }
  }

  &-bold-text {
    font-weight: $xpo-fontWeight--bolder;
  }
  // TODO: define here styles for sticky when footer component is defined to be part of the shell component
  &--sticky {
    bottom: 0;
    position: sticky;
  }
}

@mixin xpo-special-status-indicators-theme($theme) {
  .xpo-Ssi {
    display: inline-flex;

    .xpo-Icon {
      width: auto;

      .mat-icon svg {
        width: auto;
      }
    }
  }
}

@mixin xpo-tab-drawer-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // TODO
  // .xpo-TabDrawer {

  //   &-nav,
  //   &-button  {
  //     background-color: mat-color($background, card);
  //     border-right: 1px solid mat-color($foreground, divider);
  //   }
  // }
}

@mixin xpo-tray-theme($theme) {
  .mat-drawer.xpo-TrayDrawer {
    $_trayDrawer--minWidth: 500px;
    $_trayDrawer--maxWidth: 10%;
    $_trayDrawer--maxWidthExtended: 90%;
    $_trayDrawer-shadow--vOffset: 3px;
    $_trayDrawer-shadow--blur: 9px;
    $_trayDrawer-shadow--color: rgba(0, 0, 0, 0.35);

    max-width: $_trayDrawer--maxWidth;
    min-width: $_trayDrawer--minWidth;
    overflow: hidden;
    width: $_trayDrawer--maxWidth;

    &.xpo-TrayDrawer--extended {
      max-width: $_trayDrawer--maxWidthExtended;
      width: $_trayDrawer--maxWidthExtended;
    }

    &:not(.mat-drawer-side),
    &.mat-drawer-side {
      border: 1px solid $xpo-grey--300;
      box-shadow: 0 $_trayDrawer-shadow--vOffset $_trayDrawer-shadow--blur 0 $_trayDrawer-shadow--color;
    }

    .mat-drawer-inner-container {
      overflow: hidden;
    }
  }
}

@mixin TextTreatment-header--small() {
  font-size: $xpo-header-fontSize--small;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--small;
  line-height: $xpo-header-lineHeight--small;
}

@mixin TextTreatment-header--medium() {
  font-size: $xpo-header-fontSize--medium;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--medium;
  line-height: $xpo-header-lineHeight--medium;
}

@mixin TextTreatment-header--large() {
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
  letter-spacing: $xpo-header-letterSpacing--large;
  line-height: $xpo-header-lineHeight--large;
}

// Regular text mixins
@mixin TextTreatment--small() {
  font-size: $xpo-fontSize--small;
  line-height: $xpo-lineHeight--small;
}

@mixin TextTreatment--medium() {
  font-size: $xpo-fontSize--medium;
}

@mixin TextTreatment--large() {
  font-size: $xpo-fontSize--large;
}


/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #000000;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;
$xpo-fontWeight--bolder: 700;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$ngx-theBlackRoboto: 900;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: #0288d1;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;


///
// Overrides for the tooltip
///

$xpo-tooltip-arrow-margin: 18px;
$xpo-tooltip-arrow-width: 10px;
$xpo-tooltip-arrow-height: 10px;

@mixin xpo-tooltip-theme($theme) {
  .cdk-overlay-container {
    .mat-tooltip-panel {
      .mat-tooltip {
        background: $xpo-grey--1100;
        border-radius: 0;
        color: $xpo-white;
        display: flex;
        font-size: $xpo-fontSize--medium;
        // need to use same font-size for line-height
        // to keep the spaces between text and box border as they are in the visual specs
        line-height: $xpo-fontSize--medium;
        overflow: visible;
        padding: $xpo-contentSpacing;
        position: relative;
      }

      //
      // Selectors to find the element that is positioned "above", "below", "before", "after", "right" or "left"
      // This is a combination between the .mat-tooltip-panel and .mat-tooltip inline style
      //

      // This selector let us find the position "above"
      &[style*='bottom'][style*='left'] {
        // Selector to find the element positioned: "above"
        .mat-tooltip[style*='center'][style*='bottom'] {
          margin-bottom: $xpo-tooltip-arrow-margin;

          &::after {
            border-bottom: none;
            border-left: $xpo-tooltip-arrow-width solid transparent;
            border-right: $xpo-tooltip-arrow-width solid transparent;
            border-top: $xpo-tooltip-arrow-height solid $xpo-grey--1100;
            content: '';
            height: 0;
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translateX(-50%);
            width: 0;
          }
        }
      }

      // This selector let us find the positions "below", "after", "right"
      &[style*='top'][style*='left'] {
        // Selector to find the element positioned: "below"
        .mat-tooltip[style*='center'][style*='top'] {
          margin-top: $xpo-tooltip-arrow-margin;

          &::before {
            border-bottom: $xpo-tooltip-arrow-height solid $xpo-grey--1100;
            border-left: $xpo-tooltip-arrow-width solid transparent;
            border-right: $xpo-tooltip-arrow-width solid transparent;
            border-top: none;
            bottom: 100%;
            content: '';
            height: 0;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 0;
          }
        }

        // // Selector to find the element positioned:  "after", "right"
        .mat-tooltip[style*='center'][style*='left'] {
          margin-left: $xpo-tooltip-arrow-margin;

          &::before {
            border-bottom: $xpo-tooltip-arrow-width solid transparent;
            border-left: $xpo-tooltip-arrow-height solid transparent;
            border-right: $xpo-tooltip-arrow-height solid $xpo-grey--1100;
            border-top: $xpo-tooltip-arrow-width solid transparent;
            content: '';
            height: 0;
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
          }
        }
      }

      // // Selector to find the element positioned: "before", "left"
      &[style*='top'][style*='right'] {
        //position before, left
        .mat-tooltip[style*='center'][style*='right'] {
          margin-right: $xpo-tooltip-arrow-margin;

          &::after {
            border-bottom: $xpo-tooltip-arrow-width solid transparent;
            border-left: $xpo-tooltip-arrow-height solid $xpo-grey--1100;
            border-right: $xpo-tooltip-arrow-height solid transparent;
            border-top: $xpo-tooltip-arrow-width solid transparent;
            content: '';
            height: 0;
            left: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
          }
        }
      }
    }
  }
}


// Create a theme.
@mixin xpo-material-internal-theme($theme) {
  @include xpo-mat-core-theme($theme);

  @include xpo-card-theme($theme);

  @include xpo-button-theme($theme, ngx-buttonConfig($accent-hover-color: $xpo-grey--400, $hover-color: $xpo-grey--400, $primary-hover-color: $xpo-grey--400));
  @include xpo-applicationSwitcher-theme($theme);
  @include xpo-autocomplete-theme($theme);
  @include xpo-badge-theme($theme);
  @include xpo-button-toggle-group-theme($theme);
  @include xpo-checkbox-theme($theme);
  @include xpo-chips-theme($theme);
  @include xpo-datePicker-theme($theme);
  @include xpo-date-range-picker-theme($theme);
  @include xpo-dialog-theme($theme);
  @include xpo-expansion-panel-theme($theme);
  @include xpo-form-field-theme($theme);
  @include xpo-input-theme($theme);
  @include xpo-link-theme($theme);
  @include xpo-menu-theme($theme);
  @include xpo-snack-bar-theme($theme);
  @include xpo-table-theme($theme);
  @include xpo-tabs-theme($theme);
  @include xpo-radio-theme($theme);
  @include xpo-select-theme($theme);
  @include xpo-slide-toggle-theme($theme);
  @include xpo-special-status-indicators-theme($theme);
  @include xpo-tray-theme($theme);
  @include xpo-tooltip-theme($theme);

  // Xpo Component themes
  @include xpo-download-button-theme($theme);
  @include xpo-header-theme($theme);
  @include xpo-tab-drawer-theme($theme);

  // Material themes that dont need overrides since they match our style already
  @include mat-progress-bar-theme($theme);
  @include mat-progress-spinner-theme($theme);
  @include mat-sidenav-theme($theme);

  // TODO: remove when xpo-themed
  @include mat-tooltip-theme($theme);
  @include mat-badge-theme($theme);

  mat-dialog-container.mat-dialog-container {
    padding: 0;
  }
}

// TODO: introduce our greys?
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$xpo-grey--950: #333333; // TODO: this is what is spec'd out for dark card backgrounds, but its not in our var list

$xpo-blue-palette: (
  100: $xpo-blue--100,
  300: $xpo-blue--100,
  400: $xpo-blue--400,
  900: $xpo-blue--900,
  contrast: (
    100: $xpo-white,
    300: $xpo-white,
    400: $xpo-white,
    900: $xpo-white,
  ),
);

$xpo-grey-palette: (
  100: $xpo-grey--100,
  300: $xpo-grey--300,
  400: $xpo-grey--400,
  900: $xpo-grey--900,
  contrast: (
    100: $xpo-grey--900,
    300: $xpo-grey--900,
    400: $xpo-grey--900,
    900: $xpo-white,
  ),
);

$xpo-red-palette: (
  100: $xpo-red--100,
  300: $xpo-red--300,
  400: $xpo-red--400,
  900: $xpo-red--900,
  contrast: (
    100: $xpo-white,
    300: $xpo-white,
    400: $xpo-white,
    900: $xpo-white,
  ),
);

// Background palette for light themes.
$xpo-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: $xpo-white,
  background: $xpo-backgroundColor--body,
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
);

// Background palette for dark themes.
$xpo-dark-theme-background: (
  status-bar: black,
  app-bar: $xpo-grey--950,
  background: $xpo-grey--1000,
  hover: rgba(white, 0.04),
  card: $xpo-grey--950,
  dialog: $xpo-grey--950,
  disabled-button: rgba(white, 0.12),
  raised-button: $xpo-grey--950,
  focused-button: $light-focused,
  selected-button: map_get($mat-grey, 900),
  selected-disabled-button: $xpo-grey--950,
  disabled-button-toggle: black,
  unselected-chip: map_get($mat-grey, 700),
  disabled-list-option: black,
);

// Foreground palette for light themes.
$xpo-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Foreground palette for dark themes.
$xpo-dark-theme-foreground: (
  base: white,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-secondary-text,
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

// TODO: introduce our greys?
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$xpo-grey--950: #333333; // TODO: this is what is spec'd out for dark card backgrounds, but its not in our var list

$xpo-blue-palette: (
  100: $xpo-blue--100,
  300: $xpo-blue--100,
  400: $xpo-blue--400,
  900: $xpo-blue--900,
  contrast: (
    100: $xpo-white,
    300: $xpo-white,
    400: $xpo-white,
    900: $xpo-white,
  ),
);

$xpo-grey-palette: (
  100: $xpo-grey--100,
  300: $xpo-grey--300,
  400: $xpo-grey--400,
  900: $xpo-grey--900,
  contrast: (
    100: $xpo-grey--900,
    300: $xpo-grey--900,
    400: $xpo-grey--900,
    900: $xpo-white,
  ),
);

$xpo-red-palette: (
  100: $xpo-red--100,
  300: $xpo-red--300,
  400: $xpo-red--400,
  900: $xpo-red--900,
  contrast: (
    100: $xpo-white,
    300: $xpo-white,
    400: $xpo-white,
    900: $xpo-white,
  ),
);

// Background palette for light themes.
$xpo-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: $xpo-white,
  background: $xpo-backgroundColor--body,
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
);

// Background palette for dark themes.
$xpo-dark-theme-background: (
  status-bar: black,
  app-bar: $xpo-grey--950,
  background: $xpo-grey--1000,
  hover: rgba(white, 0.04),
  card: $xpo-grey--950,
  dialog: $xpo-grey--950,
  disabled-button: rgba(white, 0.12),
  raised-button: $xpo-grey--950,
  focused-button: $light-focused,
  selected-button: map_get($mat-grey, 900),
  selected-disabled-button: $xpo-grey--950,
  disabled-button-toggle: black,
  unselected-chip: map_get($mat-grey, 700),
  disabled-list-option: black,
);

// Foreground palette for light themes.
$xpo-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Foreground palette for dark themes.
$xpo-dark-theme-foreground: (
  base: white,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-secondary-text,
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);


// Creates a container object for a light theme to be given to individual component theme mixins.
@function xpo-light-theme($primary, $accent, $warn) {
  @return (primary: $primary, accent: $accent, warn: $warn, is-dark: false, foreground: $xpo-light-theme-foreground, background: $xpo-light-theme-background);
}

// Creates a container object for a dark theme to be given to individual component theme mixins.
@function xpo-dark-theme($primary, $accent, $warn) {
  @return (primary: $primary, accent: $accent, warn: $warn, is-dark: true, foreground: $xpo-dark-theme-foreground, background: $xpo-dark-theme-background);
}


// Include non-theme styles for core.
@include xpo-mat-core();

// Define a theme.
$primary: mat-palette($xpo-blue-palette, 400, 300, 900);
$accent: mat-palette($xpo-grey-palette, 900, 400, 900);
$warn: mat-palette($xpo-red-palette, 400, 300, 900);

$theme: xpo-light-theme($primary, $accent, $warn);

// Include all theme styles for the components.
@include xpo-material-internal-theme($theme);

// THIS IS JUST HERE UNTIL WE CAN GET THEM IN ARC
// TODO: Anything here should be back-ported to ARC
.u-flex--fillSpace {
  flex: 1;
}

.u-flex--spaceBetween {
  justify-content: space-between;
}
